import { useCallback, useMemo, useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router';

import { usePush } from '@hh.ru/redux-spa-middleware';

import { useSetEmployerVacancyListDefault } from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import { useSelector } from 'src/hooks/useSelector';

import { EmployerPageTab, isEmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';

interface UseCurrentTabResult {
    currentTab: EmployerPageTab;
    setCurrentTab: (newTab: string, skipFilters?: boolean) => void;
    skipFilters: boolean;
}

const DEFAULT_TAB = EmployerPageTab.Description;

const useCurrentTab = (): UseCurrentTabResult => {
    const showEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => !!showEmployerVacancySearch);
    const hasChameleon = useSelector(({ hasChameleon }) => !!hasChameleon);
    const { setEmployerVacancyListDefault } = useSetEmployerVacancyListDefault();

    const [currentTab, setCurrentTabLocal] = useState<EmployerPageTab>(DEFAULT_TAB);
    const [skipFilters, setSkipFilters] = useState<boolean>(false);

    const push = usePush();
    const location = useLocation();
    const match = useRouteMatch<{ 0: string }>();

    const getDefaultTabUrl = useCallback((): string => {
        if (!match?.path || !match?.params?.[0]) {
            return '/';
        }

        return match.path.includes('/employer/edit/simple')
            ? '/employer/edit/simple/'
            : `/employer/${match.params[0]}/`;
    }, [match]);

    const handleVacanciesTab = useCallback(
        (newTab: string): void => {
            if (showEmployerVacancySearch && newTab === EmployerPageTab.Vacancies) {
                setEmployerVacancyListDefault();
            }
        },
        [showEmployerVacancySearch, setEmployerVacancyListDefault]
    );

    const setCurrentTab = useCallback(
        (newTab: string, _skipFilters = false): void => {
            if (!isEmployerPageTab(newTab) || newTab === currentTab) {
                return;
            }

            handleVacanciesTab(newTab);

            if (hasChameleon && currentTab === EmployerPageTab.Vacancies && newTab === EmployerPageTab.Description) {
                const defaultTabUrl = getDefaultTabUrl();
                const searchParams = location?.search ?? '';
                push(`${defaultTabUrl}${searchParams}`);
            } else {
                setCurrentTabLocal(newTab);
            }

            if (!showEmployerVacancySearch) {
                setSkipFilters(_skipFilters);
            }
        },
        [
            currentTab,
            hasChameleon,
            location?.search,
            push,
            showEmployerVacancySearch,
            getDefaultTabUrl,
            handleVacanciesTab,
        ]
    );

    return useMemo(
        () => ({
            currentTab,
            setCurrentTab,
            skipFilters,
        }),
        [currentTab, setCurrentTab, skipFilters]
    );
};

export { useCurrentTab };
