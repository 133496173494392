import { useRef, useEffect, MutableRefObject } from 'react';

import elementSpy, { Bound } from 'bloko/common/elementSpy';

import { useSelector } from 'src/hooks/useSelector';
import { UXFeedback } from 'src/utils/uxfeedback';

const useSendUxFeedbackOnVacancyList = (): {
    elementSpyRef: MutableRefObject<HTMLDivElement | null>;
} => {
    const elementSpyRef = useRef<HTMLDivElement>(null);
    const showEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => showEmployerVacancySearch);
    const isEmployerVacancySearchAllowed = useSelector((state) => state.isEmployerVacancySearchAllowed);

    useEffect(() => {
        const element = elementSpyRef.current;
        if (isEmployerVacancySearchAllowed && element) {
            const { stopSpying } = elementSpy(element, {
                onShow() {
                    stopSpying();
                    // триггер события может сработать до того как ux-feedback сходит на свой сервак
                    // и получит список разрешенных триггеров текущей страницы
                    setTimeout(() => {
                        UXFeedback.sendEvent(
                            showEmployerVacancySearch ? 'employer_vacancy_search_view' : 'employer_vacancy_tree_view'
                        );
                    }, 1000);
                },
                trackingBounds: [Bound.Top],
            });
        }
    }, [isEmployerVacancySearchAllowed, showEmployerVacancySearch]);

    return { elementSpyRef };
};

export { useSendUxFeedbackOnVacancyList };
