import { connect } from 'react-redux';

import { H2 } from 'bloko/blocks/header';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { AppStore } from 'src/app/store';
import { useExpEmployerVacancySearch } from 'src/components/Employer/VacancySearch/useExpEmployerVacancySearch';
import { useSendUxFeedbackOnVacancyList } from 'src/components/Employer/VacancySearch/useSendUxFeedbackOnVacancyList';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import RegionVacancies from 'src/components/Employer/VacanciesGroupsByRegion/RegionVacancies';
import { Region, RegionTypes } from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';

const TrlKeys = {
    title: 'employerview.title',
    currentRegion: 'employerview.current-region',
    otherRegions: 'employerview.other-regions',
    empty: 'employer.vacancies.none',
};

interface VacanciesGroupsProps {
    currentRegion: Region;
    otherRegions?: Region;
    employerId?: number | null;
    vacanciesCount?: number;
    hasChameleon?: boolean;
}

const VacanciesGroups: TranslatedComponent<VacanciesGroupsProps> = ({
    currentRegion,
    otherRegions,
    employerId,
    vacanciesCount,
    trls,
    hasChameleon = false,
}) => {
    const { elementSpyRef } = useSendUxFeedbackOnVacancyList();
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);
    useExpEmployerVacancySearch();

    return !vacanciesCount || !employerId ? (
        <div ref={elementSpyRef} className="employer-vacancies-empty" data-qa="vacancies-empty-message">
            <Text size={TextSize.Large}>{trls[TrlKeys.empty]}</Text>
        </div>
    ) : (
        <div ref={elementSpyRef}>
            {!hasChameleon && (
                <div
                    className={
                        isMagritteEmployerPageHeaderExp
                            ? 'employer-vacancies-header-new-header'
                            : 'employer-vacancies-header'
                    }
                >
                    <H2>{trls[TrlKeys.title]}</H2>
                </div>
            )}

            <RegionVacancies
                region={currentRegion}
                title={`${trls[TrlKeys.currentRegion]} ${currentRegion?.name || ''}`}
                employerId={employerId}
                regionType={RegionTypes.Current}
                expanded
            />
            {!!otherRegions && (
                <RegionVacancies
                    region={otherRegions}
                    title={trls[TrlKeys.otherRegions]}
                    employerId={employerId}
                    regionType={RegionTypes.Other}
                    expanded={false}
                />
            )}
        </div>
    );
};

export default connect(({ vacanciesGroupsByRegion, employerInfo }: AppStore) => ({
    vacanciesCount: vacanciesGroupsByRegion?.count,
    currentRegion: vacanciesGroupsByRegion.currentRegion,
    otherRegions: vacanciesGroupsByRegion.otherRegions,
    employerId: employerInfo.id,
}))(translation(VacanciesGroups));
