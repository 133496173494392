import { Fragment, FC } from 'react';

import { H3Section } from 'bloko/blocks/header';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';

import { useToggleState } from 'src/hooks/useToggleState';

import ProfRoleExpandable from 'src/components/Employer/VacanciesGroupsByRegion/ProfRoleExpandable';
import Vacancies from 'src/components/Employer/VacanciesGroupsByRegion/Vacancies';
import {
    Region,
    RegionTypes,
    REGION_PROP_NAMES,
} from 'src/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';

interface RegionVacanciesProp {
    region: Region;
    employerId: number;
    title: string;
    expanded: boolean;
    regionType: RegionTypes;
}

const RegionVacancies: FC<RegionVacanciesProp> = ({ region, regionType, employerId, title, expanded }) => {
    const [opened, toggleOpened] = useToggleState(expanded);
    const profRoleBlock = () => {
        const profRoles = region?.countsByProfRole;
        if (!profRoles?.length) {
            return null;
        }
        return profRoles.map((profRole) => (
            <ProfRoleExpandable
                key={profRole.profRoleId}
                id={profRole.profRoleId}
                count={profRole.count}
                hasNextPage={profRole.hasNextPage}
                expanded={profRole.expanded}
                firstPageLoaded={profRole.firstPageLoaded}
                name={profRole.name}
                vacancies={profRole.vacancies || []}
                employerId={employerId}
                currentPage={profRole.currentPage}
                regionType={regionType}
            />
        ));
    };

    if (!region?.count) {
        return null;
    }

    return (
        <div data-qa={`vacancies-in-region vacancies-in-region_${REGION_PROP_NAMES[regionType].toLowerCase()}`}>
            <div className="company-vacancies-region">
                <H3Section lite Element="h4">
                    <Link
                        appearance={LinkAppearance.Pseudo}
                        onClick={toggleOpened}
                        data-qa="vacancies-in-region-switch"
                        icon={
                            <span className="company-vacancies-hint" data-qa="vacancies-in-region-counter">
                                <Text Element="span" importance={TextImportance.Tertiary}>
                                    {region.count}
                                </Text>
                            </span>
                        }
                    >
                        {title}
                    </Link>
                </H3Section>
            </div>
            {opened && (
                <Fragment>
                    <div className="company-vacancy-indent" data-qa="vacancies-plain-list">
                        <Vacancies vacanciesList={region.vacancies || []} />
                    </div>
                    {profRoleBlock()}
                </Fragment>
            )}
        </div>
    );
};

export default RegionVacancies;
