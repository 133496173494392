import { FC, PropsWithChildren, useMemo, useCallback, useEffect } from 'react';

import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { useSelector } from 'src/hooks/useSelector';

import PageTabsContext, { EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import PageTabsMobileSupport from 'src/components/Employer/PageTabs/PageTabsMobileSupport';
import { useCurrentTab } from 'src/components/Employer/PageTabs/useCurrentTab';

const PageTabsProvider: FC<PropsWithChildren> = ({ children }) => {
    const { currentTab, setCurrentTab, skipFilters } = useCurrentTab();
    const isMobileView = [Breakpoint.XS, Breakpoint.S].includes(useBreakpoint());
    const hasChameleon = useSelector((state) => state.hasChameleon);

    const showContentForTab = useCallback((tab: EmployerPageTab) => currentTab === tab, [currentTab]);

    useEffect(() => {
        if (hasChameleon && !isMobileView && currentTab === EmployerPageTab.Vacancies) {
            setCurrentTab(EmployerPageTab.Description);
        }
    }, [currentTab, hasChameleon, isMobileView, setCurrentTab]);

    const value = useMemo(
        () => ({
            currentTab,
            setCurrentTab,
            isMobileView,
            showContentForTab,
            skipFilters,
        }),
        [currentTab, isMobileView, setCurrentTab, showContentForTab, skipFilters]
    );

    return (
        <PageTabsContext.Provider value={value}>
            <PageTabsMobileSupport>{children}</PageTabsMobileSupport>
        </PageTabsContext.Provider>
    );
};

export default PageTabsProvider;
